import { FC, useState } from "react";
import { Avatar } from "@mui/material";
import { HamburgerBar, MenuList, StyledDefaultHeader, StyledSearchBar } from "./Header.styles";
import { ReactComponent as StoryTrainingLogo } from "../../assets/images/storyTrainingLogo.svg";
import searchIcon from "../../assets/images/searchIcon.svg";
import avatar from "../../assets/images/Avatar.png";
import { StyledParagraph, StyleButton } from "../ProfileDropDown/ProfileDropDown.styles";
import { Button, Navbar, ProfileDropDown, TextFieldControlled } from "../../components";
import { useLoginViewModel } from "../../viewModels";
import { useNavigation } from "../../hooks";
import { useHeader } from "./useHeader";

export interface HeaderProps {
  className?: string;
  isAbsolute?: boolean;
}

export const Header: FC<HeaderProps> = ({ className, isAbsolute }) => {
  const { token, isDesktop, user, userAvatar, navigateTo, primaryButtonData, handleSubmit, control, onSubmit } =
    useHeader();

  const [isOpen, setIsOpen] = useState(false);
  return (
    <StyledDefaultHeader className={`StyledDefaultHeader ${className} ${isAbsolute ? "absolute" : "relative"}`}>
      <StoryTrainingLogo onClick={() => navigateTo("/")} className="StoryTrainingLogo cursor-pointer" />
      <MenuList isOpen={isOpen}>
        {token ? (
          <StyleButton color="secondary" className="flex items-center whitespace-nowrap lg:hidden mt-32 ml-6">
            <StyledParagraph color="secondary">{`¡Hola, ${user?.name.split(" ")[0]}!`}</StyledParagraph>
            <Avatar
              sx={{ width: { xs: 30, md: 55 }, height: { xs: 30, md: 55 } }}
              src={userAvatar?.fileUrl ? userAvatar?.fileUrl : avatar}
              alt={`Imagen usuario`}
              title={`Imagen usuario`}
            />
          </StyleButton>
        ) : null}
        <ul className={`flex gap-x-1 lg:gap-x-4 items-center h-full menu px-4 ${token ? null : "lg:pt-0 pt-28"}`}>
          <li className="CatalogOption">
            <Button color="secondary" onClick={() => navigateTo("/catalog")}>
              Catálogo
            </Button>
          </li>
          <li className="FaqButtonOption">
            <Button color="secondary" className="whitespace-nowrap" onClick={() => navigateTo("/faqs")}>
              Preguntas frecuentes
            </Button>
          </li>
          <li className="SearchBarOption">
            <StyledSearchBar>
              <TextFieldControlled
                iconSrc={searchIcon}
                placeholder="Buscar soft skills, áreas o series"
                id="searchTerm"
                name="searchTerm"
                control={control}
              />
              <Button className="whitespace-nowrap" onClick={handleSubmit(onSubmit)}>
                Buscar
              </Button>
            </StyledSearchBar>
          </li>
          {!token ? (
            <li>
              <Button
                color={primaryButtonData.isAuth ? "secondary" : "primary"}
                className="whitespace-nowrap"
                colorText={primaryButtonData.isAuth ? `${isDesktop ? "#C1101A" : "#FFFFFF"}` : "#FFFFFF"}
                onClick={() => navigateTo(primaryButtonData.redirectTo)}
              >
                {primaryButtonData.label}
              </Button>
            </li>
          ) : (
            <ProfileDropDown />
          )}
        </ul>
      </MenuList>
      <HamburgerBar
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
        className="flex-col justify-center items-center"
      >
        <span
          className={`bg-red-500 block transition-all duration-300 ease-out transform
                    h-2 w-12 rounded-sm  ${isOpen ? "rotate-45 translate-y-3" : "-translate-y-0.5"}`}
        />
        <span
          className={`bg-red-500 block transition-all duration-300 ease-out 
                    h-2 w-12 rounded-sm my-0.5 ${isOpen ? "opacity-0" : "opacity-100"}`}
        />
        <span
          className={`bg-red-500 block transition-all duration-300 ease-out transform
                    h-2 w-12 rounded-sm ${isOpen ? "-rotate-45 -translate-y-2" : "translate-y-0.5"}`}
        />
      </HamburgerBar>
      <div className="mobile-searchbar">
        <StyledSearchBar>
          <TextFieldControlled
            iconSrc={searchIcon}
            placeholder="Buscar soft skills, áreas o series"
            id="searchTermMobile"
            name="searchTerm"
            control={control}
          />
          <Button className="whitespace-nowrap" onClick={handleSubmit(onSubmit)}>
            Buscar
          </Button>
        </StyledSearchBar>
      </div>
    </StyledDefaultHeader>
  );
};
